import { useContext, useState, useEffect, useCallback } from 'react';
import { StrapiContext } from "shared/context/strapi-graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './_index.scss';

interface PreviewPopupProps {
    timeoutDuration: number;  // Duration in minutes
}

const PreviewPopup = ({ timeoutDuration = 1 }: PreviewPopupProps) => {
    const { isPreview, setIsPreview } = useContext(StrapiContext) ?? {};

    // Get the preview token from URL parameters
    const getPreviewToken = useCallback(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('preview_token') || '';
    }, []);

    const [endTime, setEndTime] = useState(() => {
        const currentTime = new Date().getTime();
        const currentToken = getPreviewToken();
        const tokenKey = `previewEndTime_${currentToken}`;
        const tokenStorageKey = `previewToken_${currentToken}`;
        const savedEndTime = localStorage.getItem(tokenKey);

        if (!savedEndTime || +savedEndTime < currentTime) {
            const newEndTime = currentTime + timeoutDuration * 60000;
            localStorage.setItem(tokenKey, newEndTime.toString());
            localStorage.setItem(tokenStorageKey, currentToken);
            return newEndTime;
        }
        return +savedEndTime;
    });

    const calculateRemainingTime = useCallback(() => {
        const currentTime = new Date().getTime();
        return Math.max(Math.floor((endTime - currentTime) / 1000), 0);
    }, [endTime]);

    const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };


    const [timer, setTimer] = useState(calculateRemainingTime);
    const [active, setActive] = useState(timer > 0);
    const [formattedTime, setFormattedTime] = useState(formatTime(timer));

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newTimer = calculateRemainingTime();
            if (newTimer <= 0) {
                clearInterval(intervalId);
                setActive(false); // Set active to false when timer reaches zero
                setFormattedTime(formatTime(0)); // Ensure 0:00 is shown
            } else {
                setTimer(newTimer);
                setFormattedTime(formatTime(newTimer));
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [calculateRemainingTime, formatTime]);

    useEffect(() => {
        const currentToken = getPreviewToken();
        const tokenKey = `previewEndTime_${currentToken}`;
        const savedEndTime = localStorage.getItem(tokenKey);
        const currentTime = new Date().getTime();

        if (!savedEndTime || +savedEndTime < currentTime) {
            const newEndTime = currentTime + timeoutDuration * 60000;
            localStorage.setItem(tokenKey, newEndTime.toString());
            localStorage.setItem(`previewToken_${currentToken}`, currentToken);
            setEndTime(newEndTime);
        }
    }, [getPreviewToken, timeoutDuration]);

    if (!isPreview || !setIsPreview) return null;

    const onClick = () => {
        const currentToken = getPreviewToken();
        const tokenKey = `previewEndTime_${currentToken}`;

        // Remove the query parameter, reset preview state, and clear localStorage
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete('preview_token');
        window.history.replaceState({}, '', currentUrl.toString());

        setIsPreview(false);
        localStorage.removeItem(tokenKey);
        localStorage.removeItem(`previewToken_${currentToken}`);
        setActive(false);
    };

    return (
        <div className='previewPopUp'>
            <div className='previewPopUp__header1'>
                {active ? <FontAwesomeIcon icon={faEye} size='xl' /> : <FontAwesomeIcon icon={faEyeSlash} size='xl' />}
            </div>
            <div className='previewPopUp__content'>
                <div className='previewPopUp__header2'>
                    <div className={`previewPopUp__indicator previewPopUp__indicator--${active ? 'active' : 'inactive'}`} />
                    <p>Preview mode {active ? 'enabled' : 'disabled'}</p>
                    <div className='previewPopUp__close'>
                        <FontAwesomeIcon icon={faClose} onClick={onClick} size='xl' />
                    </div>
                </div>
                <p className='previewPopUp__countdown'>{formattedTime}</p>
            </div>
        </div>
    );
}

export default PreviewPopup;
