import React, { createContext, useState, useEffect, useContext } from 'react';
import TagManager from 'react-gtm-module';

interface GtmArgs {
  gtmId: string;
}

interface AnalyticsContextType {
  tagManager: typeof TagManager | null;
}

const Context = createContext<AnalyticsContextType>({ tagManager: null });

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [gtmArgs] = useState<GtmArgs>({
    gtmId: `${process.env.REACT_APP_GTM_ID}`,
  });

  const [tagManagerInstance, setTagManagerInstance] = useState<typeof TagManager | null>(null);

  useEffect(() => {
    if (!tagManagerInstance) {
      TagManager.initialize(gtmArgs);
      setTagManagerInstance(TagManager); 
    }
  }, [gtmArgs, tagManagerInstance]);

  return <Context.Provider value={{ tagManager: tagManagerInstance }}>{children}</Context.Provider>;
};

export const useAnalytics = () => {
  const { tagManager } = useContext(Context);
  if (!tagManager)
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  return tagManager;
};
