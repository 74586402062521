import { createContext, useCallback, useEffect, useContext, useState, useRef } from 'react';
import {
  strapiFetchGraphQL,
  strapiValidatePreviewToken
} from 'shared/lib';

import PreviewPopUp from 'components/Strapi/PreviewPopUp';

type StrapiProviderProps = {
  children: React.ReactNode;
  apiPath?: string;
  customQuery?: string;
  pageID?: string;
};

type StrapiEntryAttributes = {
  [key: string]: any;
}

type ComponentData = {
  type: string; // Type of the component
  order: number; // Order of the component
  [key: string]: any; // Other component-specific data
};

type StrapiEntry<T = object> = {
  id: string;
  attributes: StrapiEntryAttributes & Partial<T>;
  components: ComponentData[];
};

type DataResponse = {
  data: {
    [key: string]: {
      data: StrapiEntry[];
      meta: {
        pagination: {
          total: number;
        };
      };
    } | undefined;
  };
  isPreview?: boolean;
  setIsPreview?: React.Dispatch<React.SetStateAction<boolean>>;
};

interface FilterFields {
  keywords?: string[];
  frequentSearches?: string;
}

interface FilterBy {
  keywords?: string[];
  frequentSearches?: string[];
}

export type CollectionConfig = {
  id: string;
  queries: {
    page?: string; // GraphQL fragment for collection fields
    components?: Record<string, string>; // Map component names to their GraphQL fragments
  }
  filterBy?: {
    keywords?: string[];
    frequentSearches?: string[];
  }
}

interface DynamicPagination {
  offset: number;
  itemsPerPage: number;
}

type Collections = {
  [key: string]: CollectionConfig;
}

interface UseStrapiOptions {
  collections: Collections;
  slug?: string | null;
  dynamicPagination?: DynamicPagination;
  filters?: Record<string, string | string[]>;
  filterFields?: FilterFields;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstFetch?: boolean;
  itemCounts?: Record<string, number>;
  isPaginationUpdate?: boolean;
  passedCounts?: Record<string, number>;
}

type FetchedData = {
  id: string;
  attributes: Partial<StrapiEntryAttributes>;
  components: ComponentData[];
  collectionId: string;
};

type UseStrapiReturnType = {
  pageData: FetchedData[];
  fetchData: () => Promise<void>;
  contextValues: { [key: string]: any };
  collectionCounts: Record<string, number>;
};

interface CacheEntry {
  fetchedData: any; // Replace 'any' with the specific type if known
  collectionCounts: any; // Replace 'any' with the specific type if known
}

export const StrapiContext = createContext<DataResponse | undefined>(undefined);


// Function to detect preview_token from the URL's query parameters
function getPreviewTokenFromQuery(): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('preview_token');
}

export const StrapiProvider = ({ children }: StrapiProviderProps): JSX.Element => {

  const [isPreview, setIsPreview] = useState<boolean>(false);

  const initialValue: DataResponse = { data: {}, isPreview, setIsPreview };

  useEffect(() => {
    const detectedPreviewToken = getPreviewTokenFromQuery();

    if (detectedPreviewToken) {
      // Validate the token with Strapi
      (async () => {
        const isValid = await strapiValidatePreviewToken(detectedPreviewToken);
        if (isValid) setIsPreview(true);
      })();
    }
  }, []);

  return (
    <StrapiContext.Provider value={initialValue}>
      {children}
      <PreviewPopUp timeoutDuration={1} />
    </StrapiContext.Provider>
  );
};

export const useStrapi = ({
  collections,
  slug = null,
  dynamicPagination = { offset: 0, itemsPerPage: 10 },
  filters = {},
  setIsLoading,
  // passedCounts,
  isPaginationUpdate
}: UseStrapiOptions): UseStrapiReturnType => {

  const context = useContext(StrapiContext);
  const [fetchedData, setFetchedData] = useState<FetchedData[]>([]);
  const [collectionCounts, setCollectionCounts] = useState({});
  const [fetchedItemsPerPage, setFetchedItemsPerPage] = useState<Record<number, Record<string, { start: number, limit: number }>>>({});
  const [lastPage, setLastPage] = useState<number | null>(null)
  const [dataCache, setDataCache] = useState<{ [key: string]: CacheEntry }>({});
  const hasFetchedInitialData = useRef(false);

  const { isPreview } = context ?? {};

  const fetchData = useCallback(async () => {
    // Check if itemsPerPage is less than the number of collections
    if (dynamicPagination.itemsPerPage < Object.keys(collections).length) {
      throw new Error("Items per page cannot be less than the number of collections.");
    }

    const cacheKey = `${dynamicPagination.offset}-${JSON.stringify(filters)}`;
    if (dataCache[cacheKey]) {
      setFetchedData(dataCache[cacheKey].fetchedData);
      setCollectionCounts(dataCache[cacheKey].collectionCounts);
      return;
    }

    setIsLoading && setIsLoading(true);
    try {
      const currentPage = Math.floor(dynamicPagination.offset / dynamicPagination.itemsPerPage) + 1;
      const totalCounts = await fetchCollectionTotalCounts(collections, filters, isPreview);
      const newPage = Math.floor(dynamicPagination.offset / dynamicPagination.itemsPerPage) + 1;
      const navigationDirection = !lastPage || newPage > lastPage ? 'forward' : 'backward';
      const newFetchedItemsPerPage = { ...fetchedItemsPerPage };

      // Handling skipped pages when navigating forward
      if (navigationDirection === 'forward') {
        for (let skippedPage = (lastPage || 0) + 1; skippedPage < currentPage; skippedPage++) {
          // Calculate what would have been fetched on each skipped page

          const skippedPageLimits = calculateCollectionLimits(
            totalCounts,
            dynamicPagination.itemsPerPage,
            (skippedPage - 1) * dynamicPagination.itemsPerPage,
            newFetchedItemsPerPage
          );

          newFetchedItemsPerPage[skippedPage] = skippedPageLimits;
        }
      }

      if (navigationDirection === 'backward') {
        // Reset future pages when navigating backward
        Object.keys(newFetchedItemsPerPage).forEach(page => {
          const pageNumber = parseInt(page, 10);
          if (pageNumber > currentPage) {
            delete newFetchedItemsPerPage[pageNumber];
          }
        });
      }

      // Calculate limits for each collection
      const collectionLimits = calculateCollectionLimits(
        totalCounts,
        dynamicPagination.itemsPerPage,
        dynamicPagination.offset,
        newFetchedItemsPerPage
      );

      newFetchedItemsPerPage[currentPage] = collectionLimits;

      // Build queries for each collection and combine them
      const allCollectionQueries = Object.entries(collections).map(([key, collection]) => {
        const { start, limit } = collectionLimits[key];
        return buildCollectionQuery(collection, filters, start, limit, slug, isPreview);
      }).join('\n');

      // Create one big query
      const combinedQuery = `#graphql
        query {
          ${allCollectionQueries}
        }
      `;

      // Execute the combined query
      const response = await strapiFetchGraphQL({ query: combinedQuery });
      const responseData = response as DataResponse;

      if (responseData && responseData.data) {

        // Process and combine data from all collections
        let combinedData: FetchedData[] = [];
        Object.entries(collections).forEach(([_, collection]) => {
          // Check if collectionData exists and is not null
          const collectionData = responseData.data[collection.id];
          if (collectionData && Array.isArray(collectionData.data)) {
            combinedData = [
              ...combinedData,
              ...collectionData.data.map((entry: StrapiEntry) => processEntry(entry, collection.id))
            ];
          }
        });

        if (navigationDirection === 'forward') {
          Object.entries(collectionLimits).forEach(([collectionKey, { start, limit }]) => {
            // Check if the page exists in newFetchedItemsPerPage, if not, initialize it
            if (!newFetchedItemsPerPage[currentPage]) {
              newFetchedItemsPerPage[currentPage] = {};
            }

            // Update the start and limit for the current collection on the current page
            newFetchedItemsPerPage[currentPage][collectionKey] = { start, limit };
          });
        }
        setFetchedItemsPerPage(newFetchedItemsPerPage);

        // Sort combined data if needed
        combinedData.sort((a, b) => {
          const dateA = a.attributes.publishedAt ? new Date(a.attributes.publishedAt).getTime() : 0;
          const dateB = b.attributes.publishedAt ? new Date(b.attributes.publishedAt).getTime() : 0;
          return dateB - dateA;
        });

        const updatedCounts: Record<string, number> = {};

        Object.entries(responseData.data).forEach(([collectionKey, collectionData]) => {
          // Update the count for this collection
          if (collectionData?.meta?.pagination?.total !== undefined) {
            updatedCounts[collectionKey] = collectionData.meta.pagination.total;
          }
        });

        setFetchedData(combinedData);
        setCollectionCounts(updatedCounts);
        setLastPage(currentPage);

        setDataCache({
          ...dataCache,
          [cacheKey]: {
            fetchedData: combinedData,
            collectionCounts: updatedCounts
          }
        });
      }
    } catch (error) {
      console.error(`Error fetching data:`, error);
    } finally {
      if (setIsLoading) setIsLoading(false);
    }

  }, [filters, dynamicPagination.offset, isPaginationUpdate]);

  useEffect(() => {
    const detectedPreviewToken = getPreviewTokenFromQuery();
    const shouldFetchData = detectedPreviewToken ? isPreview : !isPreview;

    if (shouldFetchData && !hasFetchedInitialData.current) {
      fetchData()
      hasFetchedInitialData.current = true;
    }

  }, [isPreview])

  return {
    pageData: fetchedData,
    fetchData,
    contextValues: { ...context },
    collectionCounts
  };
};

function processEntry(entry: StrapiEntry, collectionId: string): FetchedData {
  let orderedComponents: ComponentData[] = [];
  if (entry.components) {
    orderedComponents = processComponents(entry.components);
  }

  return {
    ...entry,
    components: orderedComponents,
    collectionId: collectionId
  };
}

// Helper function to process components from Strapi format to a more usable format
function processComponents(components: ComponentData[]): ComponentData[] {
  const orderedComponents: ComponentData[] = [];
  if (!components || !Object.keys(components)[0]) return orderedComponents

  Object.keys(components).forEach(componentKey => {
    components[componentKey] && components[componentKey].forEach((componentData: ComponentData, index: number) => {
      if (componentData && Object.keys(componentData).length > 0) {
        orderedComponents.push({ ...componentData, type: componentKey, order: index });
      }
    });
  });
  orderedComponents.sort((a, b) => a.order - b.order);
  return orderedComponents;
}

// Reusable function to build a GraphQL query for a single collection
function buildCollectionQuery(
  collection: CollectionConfig,
  filters: Record<string, string | string[]>,
  start: number,
  limit: number,
  slug?: string | null,
  isPreview?: boolean,
  sortField = 'publishedAt',
  fetchOnlyCounts = false
) {
  const filterQueryPart = buildFilters(filters, collection.filterBy);

  const componentQueries = !fetchOnlyCounts && collection.queries.components
    ? Object.entries(collection.queries.components)
      .map(([component, fields]) => `${component}: content { ${fields} }`)
      .join("\n")
    : '';

  // Check if the slug contains '/*' and replace it with the current URL (for dynamic routes)
  if (slug && slug.includes('/*')) slug = window.location.pathname;

  const paginationPart = fetchOnlyCounts
    ? 'pagination: { limit: 0 }' // No need to fetch items, just count
    : `pagination: {
      start: ${start},
      limit: ${limit ? limit : 0}
    }`;

  return `
    ${collection.id}${`(${slug || filterQueryPart ?
      `filters: {
        ${slug ? `slug: { eq: "${slug}" } ` : ``}
        ${filterQueryPart ? filterQueryPart : ``}
      },` : ``}
      sort: "${sortField}:desc"
      ${paginationPart}
      publicationState: ${isPreview ? "PREVIEW" : "LIVE"}
    )`} {
      data {
        ${collection.queries.page}
        ${componentQueries ? `components: attributes { ${componentQueries} }` : ''}
      }
      meta {
        pagination {
          total
        }
      }
    }
  `;
}

const buildFilters = (filters: Record<string, string | string[]>, filterBy: FilterBy | undefined) => {
  if (!filterBy) return '';

  const orFilterParts: string[] = [];

  // Helper function to recursively build nested filters
  const buildNestedFilter = (field: string, value: string[] | string): string => {
    const parts = field.split('.');
    if (parts.length === 1) {
      return `{ ${field}: { containsi: "${value}" } }`;
    } else {
      const [firstPart, ...rest] = parts;
      return `{ ${firstPart}: ${buildNestedFilter(rest.join('.'), value)} }`;
    }
  };

  // Handle keywords
  if (filters.keywords && filterBy.keywords) {
    filterBy.keywords.forEach(field => {
      if (filters.keywords) {
        orFilterParts.push(buildNestedFilter(field, filters.keywords));
      }
    });
  }

  // Handle frequent searches
  if (filters.frequentSearches && Array.isArray(filters.frequentSearches) && filters.frequentSearches.length > 0) {
    filters.frequentSearches.forEach(term => {
      filterBy.keywords?.forEach(field => {
        orFilterParts.push(buildNestedFilter(field, term.trim()));
      });
    });
  }

  // Combine all filters in a single 'or' statement
  return orFilterParts.length > 0 ? `or: [${orFilterParts.join(', ')}]` : '';
};

async function fetchCollectionTotalCounts(collections: Collections, filters: Record<string, string | string[]>, isPreview?: boolean) {
  const totalCounts: Record<string, number> = {};

  let combinedQuery = '';
  // Build the combined query
  for (const [_, collection] of Object.entries(collections)) {
    const queryPart = buildCollectionQuery(collection, filters, 0, 0, null, isPreview, 'publishedAt', true); // Set limit to 0 to fetch only count
    combinedQuery += `${queryPart}\n`;
  }

  // Fetch data with a single request
  const data = await strapiFetchGraphQL({
    query: `#graphql
      query {
        ${combinedQuery}
      }
    `,
  });

  for (const [key, collection] of Object.entries(collections)) {
    totalCounts[key] = data.data[collection.id].meta.pagination.total;
  }

  return totalCounts;
}

function calculateCollectionLimits(
  totalCounts: Record<string, number>,
  itemsPerPage: number,
  currentPageOffset: number,
  fetchedItemsPerPage: Record<number, Record<string, { start: number, limit: number }>>
) {
  const limits: Record<string, { start: number, limit: number }> = {};
  const currentPage = Math.floor(currentPageOffset / itemsPerPage) + 1;
  let itemsRemainingOnPage = itemsPerPage;

  Object.entries(totalCounts).forEach(([collectionKey, totalItems], index) => {
    let itemsFetchedFromCollection = 0;

    // Calculate total items fetched from this collection across all previous pages
    for (let i = 1; i < currentPage; i++) {
      itemsFetchedFromCollection += (fetchedItemsPerPage[i] && fetchedItemsPerPage[i][collectionKey]) ? fetchedItemsPerPage[i][collectionKey].limit : 0;
    }

    const start = itemsFetchedFromCollection;
    const availableItems = totalItems - start;
    const remainingCollections = Object.keys(totalCounts).length - index;

    // Evenly distribute the remaining items on the page across the remaining collections
    let limit = availableItems > 0 ? Math.min(availableItems, Math.ceil(itemsRemainingOnPage / remainingCollections)) : 0;

    // Check if the collection can contribute to this page
    if (availableItems > 0 && itemsRemainingOnPage > 0) {
      itemsRemainingOnPage -= limit;
    } else {
      limit = 0;
    }

    limits[collectionKey] = { start, limit };
  });

  // If there are still items remaining, distribute them starting from collections with fewer items
  if (itemsRemainingOnPage > 0) {
    Object.keys(limits).sort((a, b) => totalCounts[a] - totalCounts[b]).forEach(collectionKey => {
      const additionalItems = Math.min(totalCounts[collectionKey] - limits[collectionKey].start - limits[collectionKey].limit, itemsRemainingOnPage);
      limits[collectionKey].limit += additionalItems;
      itemsRemainingOnPage -= additionalItems;
    });
  }

  return limits;
}