import * as React from "react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { App } from "./App";
import { StrapiProvider } from 'shared/context/strapi-graphql';
import { AnalyticsProvider } from "shared/context/google-analytics";


const container = document.getElementById("root");
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);


root.render(
  <React.StrictMode>
      <StrapiProvider>
        <AnalyticsProvider>
          <App />
        </AnalyticsProvider>
      </StrapiProvider>
  </React.StrictMode>,
);

reportWebVitals(() => {});

