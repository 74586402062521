import React, { useState } from "react";
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import StrapiImage from 'components/Strapi/Image';
import StrapiButton from 'components/Strapi/Button';
import Wrapper from "components/Strapi/Wrapper";

import { Image as ImageProps } from 'shared/interfaces/Image';
import { Button as ButtonProps } from 'shared/interfaces/Button';
import Text from 'components/Text';

import 'pure-react-carousel/dist/react-carousel.es.css';
import './stylesheets/accordion.scss';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';
import { mapColor } from "shared/helpers/colorMapping";

export type AccordionData = {
  topTitle: string;
  topSubTitle?: string;
  icon?: boolean;
  topImage?: ImageProps
  topDescription?: string;
  bgColor?: {
    selectedColor: string
  },
  content: {
    bottomImages?: ImageProps[];
    bottomHeader?: string;
    bottomHeader2?: string;
    bottomDescription?: string;
    buttons?: ButtonProps[];
  }[]
}

export type AccordionProps = {
  title?: string,
  description?: string,
  sectionBgColor?: {
    selectedColor: string
  },
  accordion: AccordionData[],
  simpleAccordions?: any
};

type SimpleAccordionItem = {
  title?: string | undefined;
  link?: string | undefined;
  body?: React.ReactElement | string | undefined;
  rel?: boolean;
}

export type SimpleAccordionProps = {
  items: SimpleAccordionItem[]
}

type TableAccordionObjectProps = {
  title: string;
  description: string;
  sectionBgColor: {
    selectedColor: string;
  }
}

type TableAccordionProps = {
  bgColor?: {
    selectedColor: string;
  }
  accordion: TableAccordionObjectProps[]
}

export const AccordionWrapper = (props: AccordionProps): React.ReactElement => {
  return (
    <Wrapper bgColor={props?.sectionBgColor?.selectedColor}>
      <div className='accordion__container container container--large'>
        {props.title && <h2>{props.title}</h2>}
        {props.description && <Text text={props.description} className='description' />}
        <Accordion {...props} />
      </div>
    </Wrapper>
  )
}

export const Accordion = (props: AccordionProps): React.ReactElement => {
  const { accordion, sectionBgColor, simpleAccordions } = props;
  const [openStates, setOpenStates] = useState<Record<number, boolean>>({});

  const toggleOpen = (index: number) => {
    setOpenStates(prevStates => ({
      [index]: !prevStates[index]
    }));
  }

  return (
    <>
      {accordion[0] && accordion.map(({ topTitle, icon, bgColor, content }, index) => {
        const isOpen = openStates[index];

        return (
          <div className={`accordion__wrapper`} key={index}>
            <div className={
              `accordion accordion__container__top background--${mapColor(bgColor?.selectedColor || '')} ${!bgColor?.selectedColor ? 'accordion__wrapperTopBorder' : ''}`} onClick={() => toggleOpen(index)}>
              {icon && topTitle ? <h3><FontAwesomeIcon icon={faUser} />{topTitle}</h3> : <h3>{topTitle}</h3>}
              <div className='accordion__icon' >
                <FontAwesomeIcon icon={!isOpen ? faPlus : faMinus} />
              </div>
            </div>
            {isOpen && <div className={`accordion__container__bottom background--white ${!sectionBgColor?.selectedColor ? 'accordion__wrapperBottomBorder' : ''}`}>
              {content[0]
                ? content.map(({ bottomHeader, bottomHeader2, bottomDescription, buttons }, index) => {

                  return (
                    <React.Fragment key={index}>
                      {(bottomHeader || bottomHeader2 || bottomDescription) &&
                        <div>
                          <div className='accordion__bottomHeaders'>
                            {bottomHeader && <h4>{bottomHeader}</h4>}
                            {bottomHeader2 && <h5>{bottomHeader2}</h5>}
                          </div>
                          <Text text={bottomDescription} />
                        </div>
                      }
                      {buttons && buttons[0] &&
                        <div className='accordion__button-container'>
                          {buttons?.map((button, index) => {
                            return <StrapiButton data={button} key={index} />
                          })}
                        </div>
                      }
                    </React.Fragment>
                  )
                })
                : (simpleAccordions && simpleAccordions[index]) &&
                <SimpleAccordion items={simpleAccordions[index]} />
              }
            </div>}
          </div>
        )
      })}
    </>
  );
}

export const MegaAccordion = (props: AccordionProps): React.ReactElement => {
  const { accordion } = props;
  const [openStates, setOpenStates] = useState<Record<number, boolean>>({});

  const toggleOpen = (index: number) => {
    setOpenStates(prevStates => ({
      [index]: !prevStates[index]
    }));
  }

  return (
    <Wrapper bgColor={props?.sectionBgColor?.selectedColor}>
      <div className='megaAccordion accordion__container accordion container container--large'>
        {accordion?.map(({ topTitle, topSubTitle, topDescription, topImage, bgColor, content }, index) => {
          const isOpen = openStates[index];

          return (
            <React.Fragment key={index}>
              <div className={`accordion__mega--container background--${mapColor(bgColor?.selectedColor || '')}`} onClick={() => toggleOpen(index)}>
                <div className='accordion__mega--container__top '>
                  {topImage &&
                    <div className='imgWrapper'>
                      <StrapiImage data={topImage} />
                    </div>
                  }
                  <div className="accordion__mega--container__top--description">
                    <h3>{topTitle}</h3>
                    {topSubTitle && <h4>{topSubTitle}</h4>}
                    {topDescription && <Text text={topDescription} />}
                  </div>
                  <div className='accordion__icon' onClick={() => toggleOpen(index)} >
                    <FontAwesomeIcon icon={!isOpen ? faPlus : faMinus} />
                  </div>
                </div>
                {isOpen && content[0] && content.map((contentItem, index) => {
                  const { bottomImages, bottomHeader, bottomDescription, buttons } = contentItem;
                  return (
                    <div className={`accordion__mega--container__bottom`} key={index}>
                      <div className="accordion__container__bottom--carousel">
                        <AccordionMobileCarousel
                          images={bottomImages}
                        />
                      </div>
                      {bottomImages && bottomImages[0] ? <div className="accordion__mega--container__bottom--desktopImages ">
                        {bottomImages?.map((img, imgIndex) => (
                          <div className='imgWrapper' key={imgIndex} >
                            <StrapiImage data={img} />
                          </div>
                        ))}
                      </div> : null}
                      <div className="accordion__mega--container__bottom--content">
                        {bottomHeader && <h3>{bottomHeader}</h3>}
                        {bottomDescription && <Text text={bottomDescription} />}
                      </div>
                      {buttons && buttons[0] && <div className='accordion__button-container'>
                        {buttons?.map((button, index) => {
                          return (
                            <StrapiButton
                              key={index}
                              data={button}
                            />
                          )
                        })}
                      </div>}
                    </div>
                  )
                })
                }
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </Wrapper>
  );
}

const AccordionMobileCarousel = ({ images }: { images?: ImageProps[] }): React.ReactElement => {
  // Safeguard against undefined images
  const imagesToSlide = images || [];

  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={3}
        naturalSlideHeight={3}
        totalSlides={imagesToSlide.length}
        isIntrinsicHeight={true}
        dragEnabled={false}
        visibleSlides={1}
        className="accordion__carousel"
      >
        <Slider className='accordion__carousel-slider-container' classNameTrayWrap='accordion__carousel-slider-tray'>
          {imagesToSlide.map((img, idx) => (
            <Slide innerClassName="accordion__carousel__inner-slide" key={idx} index={idx}>
              <div className='imgWrapper'>
                <StrapiImage data={img} />
              </div>
            </Slide>
          ))}
        </Slider>
        {imagesToSlide.length > 1 && (
          <div className="accordion__carousel--dot-container">
            <DotGroup className='accordion__carousel-dot' />
          </div>
        )}
      </CarouselProvider>
    </div>
  );
}

export const SimpleAccordion = (props: SimpleAccordionProps): React.ReactElement => {
  const { items } = props;
  const [openStates, setOpenStates] = useState<Record<number, boolean>>({});

  const toggleOpen = (index: number) => {
    setOpenStates(prevStates => ({
      [index]: !prevStates[index]
    }));
  };

  return (
    <ul className='simple-accordion'>
      {items?.map((item, i, rel) => {
        return <li
          key={i}
          className={`simple-accordion__item${item.body ? ' simple-accordion__item--with-body' : ''}`}
          onClick={() => toggleOpen(i)}
        >
          <div className='item-title'>
            {item.link ? (
              rel ? (
                <a className='item-title__link' href={item.link} target='_blank' rel='noreferrer'>
                  {item.title}
                </a>
              ) : (
                <a className='item-title__link' href={item.link}>
                  {item.title}
                </a>
              )
            ) : (
              <>{item.title}</>
            )}
            {item.body && <span className='item-title__icon'>
              <FontAwesomeIcon icon={openStates[i] ? 'minus' : 'plus'} />
            </span>}
          </div>
          {item.body && openStates[i] && <div className='item-body'>
            {typeof (item.body) === 'string' ? parse(item.body) : item.body}
          </div>}
        </li>
      })}
    </ul>
  )
}

export const TableAccordion = (props: TableAccordionProps): React.ReactElement => {
  const { bgColor, accordion } = props;
  const [openStates, setOpenStates] = useState<Record<number, boolean>>({});

  const toggleOpen = (index: number) => {
    setOpenStates(prevStates => ({
      [index]: !prevStates[index]
    }));
  }

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className='accordion__table-container container container--large'>
        {accordion?.map(({ title, description, sectionBgColor }, index) => {
          const isOpen = openStates[index];
          return (
            <>
              <div key={index} className={`${!isOpen ? 'accordion' : 'table-accordion'} accordion__container__top background--${mapColor(sectionBgColor?.selectedColor || '')}`} onClick={() => toggleOpen(index)}>
                <h3>{title}</h3>
                <div className='accordion__icon'>
                  <FontAwesomeIcon icon={!isOpen ? faPlus : faMinus} />
                </div>
              </div>
              {isOpen &&
                <div className='accordion__container__table-bottom'>
                  <Text text={description} />
                </div>
              }
            </>
          )
        })}
      </div>
    </Wrapper>
  )
}