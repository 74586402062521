async function strapiValidatePreviewToken(token: string): Promise<boolean> {

    const response = await fetch(`${process.env.REACT_APP_VALIDATE_PREVIEW_TOKEN_URL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
    });

    const data = await response.json();
    return data.valid;
}

export default strapiValidatePreviewToken;