import React from 'react';

import { Button } from 'shared/interfaces/Button';
import { Image } from 'shared/interfaces/Image';

import StrapiButton from 'components/Strapi/Button';
import StrapiImage from 'components/Strapi/Image';
import Wrapper from "components/Strapi/Wrapper";
import Text from 'components/Text';
import { mapColor } from "shared/helpers/colorMapping"

import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import { isMobile } from 'react-device-detect';

import 'pure-react-carousel/dist/react-carousel.es.css';
import './stylesheets/collages.scss';

type CollageItem = {
  image: Image;
  button?: Button;
};

type CollageProps = {
  title: string;
  description: string;
  button: Button;
  items: CollageItem[];
  align: string | 'right';
  barColor?: {
    selectedColor: string;
  }
  transparentLogo?: boolean;
};

const buildCollageModifier = (items: CollageProps['items']) => {
  if (!items) return '';

  switch (items.length) {
    case 3:
      return 'collage--three-items';
    case 4:
      return 'collage--four-items';
    case 5:
      return 'collage--five-items';
    default:
      return '';
  }
};

const chunks = (arr: any, size: number) => {
  if (!arr) return;

  // @ts-expect-error: no-implicit-any
  const result = arr.reduce((rows, key, index) => (index % size == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows, []);
  return result;
}
const PhotoCollage = (props: CollageProps): React.ReactElement => {
  const { align, items, title, description, button, barColor, transparentLogo } = props;
  if (!props) return <></>;


  const Card = ({ item, i, isMobile = false }: { item: CollageItem, i: number, isMobile?: boolean }) => {
    return <div className={`collage__item${isMobile ? 'Mobile' : ''} item--${i + 1}`}>
      <StrapiImage data={item.image} />
      {item.button && <StrapiButton data={item.button} customClassName='collage__itemCta' />}
    </div>
  }


  return (
    <Wrapper>
      <div className={`collage collage--image-collage ${buildCollageModifier(items)}`}>
        <div className='container container--large container--less-padding'>

          {chunks(items, items.length).map((rows: CollageItem[], rowIndex: number) => {
            return <div key={rowIndex} className={`collage__row collage--${align}`}>
              <div className='collage__text'>
                <h2 className='heading'>{title}</h2>
                <Text text={description} />
                {button && <StrapiButton data={button} />}
              </div>
              <div className='collage__mobile'>
                <CarouselProvider
                  naturalSlideWidth={1}
                  naturalSlideHeight={1}
                  totalSlides={rows.length}
                  isIntrinsicHeight={true}
                  dragEnabled={isMobile}
                  visibleSlides={1}
                  className=""
                >
                  <Slider>
                    {rows.map((item, i) => (
                      <Slide key={i} index={i} className='slide-padding'><Card isMobile={true} item={item} i={i} /></Slide>
                    ))}
                  </Slider>
                  <div className="collage__dot-container">
                    <DotGroup />
                  </div>
                </CarouselProvider>
              </div>
              {rows.map((item, i) => <Card item={item} i={i} key={i} />)}
            </div>;
          })}
        </div>
        {barColor && <div className={`collage__bg ${transparentLogo ? 'collage__bgLogo' : ''} background--${mapColor(barColor?.selectedColor)}`}></div>}
      </div>
    </Wrapper>
  )
};

export default PhotoCollage;
