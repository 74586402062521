import React from 'react';
import { Button as BaseButton, ButtonProps as BaseButtonProps } from '../../Buttons';
import { Button as StrapiButtonProps } from 'shared/interfaces/Button';

// Extending the base ButtonProps with the StrapiButtonProps
interface StrapiButtonComponentProps extends BaseButtonProps {
  data: StrapiButtonProps;
}

const StrapiButton = ({ data, ...baseProps }: StrapiButtonComponentProps): React.ReactElement => {
  const { label, link, className } = data;

  return (
    <BaseButton
      {...baseProps}
      text={label}
      link={link}
      customClassName={`${baseProps.customClassName || ''} ${className ? 'button--' + className.replace('_', '-') : ''}`}
    />
  );
};

export default StrapiButton;