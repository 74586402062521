import React from 'react';

import './stylesheets/footer.scss';

export const Footer = (): React.ReactElement => {
  const copyrightYear = new Date().getFullYear();

  return (
    <div className='footerWrapper'>
      <footer className='footer'>
        Footer placeholder {copyrightYear}
      </footer>
    </div>
  )
};
