import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';

import './stylesheets/inputs.scss';
import { Button } from 'components/Buttons';

type InputProps = {
  customClassName?: string;
  name: string;
  placeholder?: string;
  inputType?: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  stateValue?: string;
};

type DropDownProps = {
  dropDownName: string;
  options: string[];
  // onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onSelectionChange?: (selectedOptions: string[]) => void; // New handler for selected options
};

interface SearchInputComponentProps {
  position: 'header-desktop' | 'header-mobile' | 'footer';
}

const Input = (props: InputProps): React.ReactElement => {
  const { customClassName, name, placeholder, inputType, label, onChange } = props;

  return (<div className='input--wrapper'>
    {label && <label>{label}</label>}
    <div className={`input ${customClassName}`}>
      <input type={inputType || "text"} name={name} id={name} placeholder={placeholder} onChange={onChange} />
    </div>
  </div>);
};

const SearchInput = (props: InputProps): React.ReactElement => {
  const { customClassName, name, placeholder, label, onChange, stateValue, type } = props;

  return (
    <div className='input--wrapper'>
      {label && <label>{label}</label>}
      <div className={`input ${!type ? 'input--search' : type} ${customClassName}`}>
        <FontAwesomeIcon icon={faSearch} />
        <input type="text" name={name} id={name} placeholder={placeholder} autoComplete='off' onChange={onChange} value={stateValue} />
      </div>
    </div>
  );
};

const SearchRedirectInput = ({ position }: SearchInputComponentProps) => {
  const [searchInput, setSearchInput] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchInput.trim() !== '') {
      window.location.href = `/search?query=${encodeURIComponent(searchInput)}`;
    }
  };

  const mobileHeaderOrFooter = ['header-mobile', 'footer'].includes(position);

  return (
    <form onSubmit={handleFormSubmit} className={`search-form search-form--${position}`}>
      {mobileHeaderOrFooter && <FontAwesomeIcon icon={faSearch} />}
      <input
        type="text"
        value={searchInput}
        onChange={handleInputChange}
        placeholder={!mobileHeaderOrFooter ? 'Search' : ''}
        className={`search-input search-input--${position}`}
      />
    </form>
  );
};

const DropDown: React.FC<DropDownProps> = ({ options, dropDownName, onSelectionChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option: string) => {
    if (!selectedOptions.includes(option)) {
      const newSelectedOptions = [...selectedOptions, option];
      setSelectedOptions(newSelectedOptions);
      onSelectionChange?.(newSelectedOptions);
    }
  };

  const removeOption = (optionToRemove: string) => {
    const newSelectedOptions = selectedOptions.filter(option => option !== optionToRemove);
    setSelectedOptions(newSelectedOptions);
    onSelectionChange?.(newSelectedOptions);
  };

  const clearSelection = () => {
    setSelectedOptions([]);
    onSelectionChange?.([]);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className='input--drop-down-container input--wrapper'>
      <label>{dropDownName}</label>
      <div className={`custom-dropdown`} ref={dropdownRef}>
        <div className={`custom-dropdown__selected ${isOpen ? 'isOpen' : ''}`} onClick={toggleDropdown}>
          <span className="custom-dropdown__placeholder">Select Options</span>
          <div className='input--drop-down-options-custom-icon-box'>
            {isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          </div>
        </div>

        {isOpen && (
          <ul className='custom-dropdown__options'>
            {options.map((option, index) => (
              <li
                key={index}
                className='custom-dropdown__option'
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}

        {selectedOptions.length > 0 && (
          // <div className='input--drop-down-selected-options'>
          //   {selectedOptions.map((option, index) => (
          //     <span key={index} className='selected-option'>
          //       {option} <button onClick={() => removeOption(option)}>x</button>
          //     </span>
          //   ))}
          // </div>
          <div className="input--drop-down-options-container">
            {selectedOptions?.map((selectedOption, index) => (
              <div key={index}>
                <Button
                  customClassName="button--selected-option-button"
                  icon={['fas', 'clone']}
                  text={selectedOption}
                  onClick={() => removeOption(selectedOption)}
                />
                {/* {selectedOption}
                </Button> */}
              </div>
            ))}
            <Button customClassName="button--clear-all-button" onClick={clearSelection} text='Clear Filters' />
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;

export {
  Input,
  SearchInput,
  SearchRedirectInput,
  DropDown
}
