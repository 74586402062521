import React from "react";
// import { arrayIsValid } from "shared/lib";

/**
 * StrapiDynamicZone Component
 *
 * A flexible renderer for dynamic content based on Strapi's component system.
 * The component accepts a map of components and data. The keys of the data object
 * determine which component is used, and the associated array provides the props for each
 * instantiation of the component.
 *
 * @component
 * @param {object} props - Props for StrapiDynamicZone component.
 * @param {object} props.components - A key-value object mapping component names to React components.
 * @param {object} props.data - A key-value object where the key is the component name, and the value is an array of data for each component instance.
 * @returns {React.ReactNode} A set of rendered components.
 * @throws Will throw an error if the components or data props are not correctly formatted.
 */

type ComponentData = {
  type: string;
  order: number;
  [key: string]: any;
};

interface StrapiDynamicZoneProps {
  components: { [key: string]: React.ComponentType<any> };
  data?: ComponentData[]; // Data is now optional
}

const StrapiDynamicZone = ({ components, data = [] }: StrapiDynamicZoneProps) => {
  const componentList: React.ReactNode[] = [];

  if (!components || Object.keys(components).length === 0) {
    throw new Error(`Error in StrapiDynamicZone: components prop must be a valid object with at least one component.`);
  }

  data.forEach((componentData, index) => {
    if (!componentData || Object.keys(componentData).length === 0) {
      return; // Skip empty or invalid component data
    }

    const componentType = componentData.type;
    if (!componentType || !components[componentType]) {
      console.warn(`Error in StrapiDynamicZone: Component type '${componentType}' is not recognized.`);
      return;
    }

    const Component = components[componentType];
    componentList.push(<Component key={`${componentType}-${index}`} {...componentData} />);
  });

  return <>{componentList}</>;
};

export default StrapiDynamicZone;