import React, { useRef, useEffect } from 'react';
import Player from '@vimeo/player';
import Wrapper from 'components/Strapi/Wrapper';

import './stylesheets/video-player.scss';

type VideoPlayerProps = {
  videoId: string;
  options?: Player.Options;
  videoType: string;
  className?: string;
};

export const VideoPlayer = ({ videoId, options, videoType, className }: VideoPlayerProps): React.ReactElement => {
  const playerContainerRef = useRef<HTMLDivElement | null>(null);
  const playerInstance = useRef<Player | null>(null);

  useEffect(() => {
    if (playerContainerRef.current) {
      if (videoType === 'vimeo') {
        const videoUrl = `https://player.vimeo.com/video/${videoId}`;
        playerInstance.current = new Player(playerContainerRef.current, {
          url: videoUrl,
          byline: false,
          portrait: false,
          title: false,
          ...options
        });
      } // No need for a cleanup function for YouTube as it's just an iframe

      return () => {
        if (playerInstance.current) {
          playerInstance.current.destroy();
        }
      };
    }
  }, [videoId]);

  if (videoType === 'youtube') {
    return (
      <div className='video-player' ref={playerContainerRef}>
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?rel=0`}
          allowFullScreen
          width='100%'
          height='100%'
        ></iframe>
      </div>
    );
  }

  // Default return for Vimeo to keep the logic clear
  return (
    <div className={`${className ? className : "video-player--vimeo"}`} ref={playerContainerRef}></div>
  );
};

export const VideoPlayerWithWrapper = (props: VideoPlayerProps) => {
  return (
    <Wrapper>
      <div className='container container--large'>
        <VideoPlayer {...props} />
      </div>
    </Wrapper>
  )
}