import './stylesheets/header.scss';

export const Head = () => {
  return (
    <header>
      Header placeholder
    </header>
  );
}

export default Head;
