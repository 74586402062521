const colorMapping: { [key: string]: string } = {
	"black": "black",
	"white": "white",
	"red": "red",
	"cfnavy": "brand",
	"cfgreen": "grass",
	"cfgreen_light": "light_grass",
	"cfblue": "blue",
	"cfblue_light": "bubbles",
	"cfperiwinkle": "dark_blue_gray",
	"cfperiwinkle_light": "light_periwinkle",
	"cforange": "pumpkin_orange",
	"cforange_light": "orange_light",
	"river_counties_blue": "river_counties_blue",
	"river_counties_blue_light": "river_counties_light_blue",
	"gloucester_green": "forest_green",
	"gloucester_green_light": "gloucester_light_green",
	"mathews_pink": "pomegranate_red",
	"mathews_pink_light": "mathews_light_pink"
  // Add any additional mappings as necessary
};

// The mapColor function takes a color name and returns the mapped color name,
// or the original color name if no mapping is found.
export const mapColor = (colorName: string): string => {
  return colorMapping[colorName] || colorName;
};