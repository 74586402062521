import React from 'react';
import { Image as IImage } from 'shared/interfaces/Image';
import './stylesheets/hero.scss';
import { Button as IButton } from 'shared/interfaces/Button';
import StrapiButton from 'components/Strapi/Button';
import { rem } from 'shared/helpers/styleHelpers';
import { VideoPlayer } from 'components/VideoPlayer/VideoPlayer';

type HeroProps = {
  height?: number;
  title?: string;
  Title?: string;
  subHead?: string;
  SubTitle?: string;
  buttonText?: string;
  bgImg?: IImage;
  cta?: IButton;
  gradient?: boolean;
  vimeoID?: string;
}

const StandardHero = (props: HeroProps): React.ReactElement => {
  const { bgImg, cta, subHead, title, Title, SubTitle, height, gradient, vimeoID } = props;

  const heroHeight = height ? rem(height) : 'auto';
  const gradientClass = gradient ? 'hero--gradient' : '';

  if (vimeoID) {
    return (
      <div className={`hero hero--large`}>
        <div className={`hero__video`}>
          <VideoPlayer className="video-player--hero" videoId={vimeoID} videoType="vimeo" options={{ responsive: true, controls: false, muted: true, autoplay: true, portrait: true, loop: true }} />
          <div className='hero__content--video'>
            <div>
              {subHead || SubTitle && <h2>{subHead || SubTitle}</h2>}
              {title || Title && <h1>{title || Title}</h1>}
            </div>
            {cta && <StrapiButton data={cta} />}
          </div>
        </div>

      </div>
    )
  }

  return (
    <div className={`hero hero--standard ${gradientClass}`} style={{ minHeight: heroHeight, backgroundImage: bgImg && `url(${bgImg.image.data.attributes.url})` }}>
      <div className='container container--large'>
        <div className='hero__content'>
          <div>
            {subHead || SubTitle && <h2>{subHead || SubTitle}</h2>}
            {title || Title && <h1>{title || Title}</h1>}
          </div>
          {cta && <StrapiButton data={cta} icon={['fas', 'alarm-clock']} />}
        </div>
      </div>
    </div>
  );
}

export default StandardHero;
