import React from 'react';
import parse from 'html-react-parser';

type DivProps = JSX.IntrinsicElements['div'];

type TextProps = DivProps & {
  text: string | undefined;
  as?: string;
  maxCharCount?: number;
  trimmed?: boolean
};

type TransformNode = {
  type: string;
  attribs?: {
    [key: string]: string;
  };
};

const trimText = (text: string, maxLength: number): string => {
  if (!text) return "";

  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }

  return text;
}

const transform = (node: TransformNode) => {
  if (node.type === 'tag' && node.attribs?.style) {
    const style = node.attribs.style.toLowerCase(); // Convert to lower case for case-insensitive matching

    const bitterRegex = /font-family:\s*['"]?bitter semibold['"]?/i;
    const openSansRegex = /font-family:\s*['"]?open sans semibold['"]?/i;

    if (bitterRegex.test(style) || openSansRegex.test(style)) {
      let classToAdd = '';

      if (bitterRegex.test(style)) {
        classToAdd = 'bitter-semibold';
      } else if (openSansRegex.test(style)) {
        classToAdd = 'open-sans-semibold';
      }

      // Add the determined class to the existing classes
      const existingClasses = node.attribs.class ? node.attribs.class.split(' ') : [];
      if (!existingClasses.includes(classToAdd)) {
        existingClasses.push(classToAdd);
      }
      node.attribs.class = existingClasses.join(' ');
    }
  }
};

export const Text = (props: TextProps): React.ReactElement => {
  const { as = 'div', text = '', className = '', maxCharCount = 480, trimmed } = props;
  if (!text) {
    return <></>
  }

  const processedText = trimmed ? trimText(text, maxCharCount) : text;

  return React.createElement(
    as,
    { className: `rte ${className}` },
    parse(processedText, { replace: transform })
  );
};

export default Text;
