import React from 'react';
import './_index.scss';
import { Image } from 'shared/interfaces/Image';

interface StrapiImageComponentProps {
  data: Image;
  className?: string;
  height?: number;
  width?: number;
}

const StrapiImageComponent = ({ data, className, height, width }: StrapiImageComponentProps): React.ReactElement => {

  const { url, alternativeText, caption } = data.image.data.attributes;

  return (
    <>
      <img
        className={`strapiImageComponent ${data.lazy ? 'lazy' : ''} ${className ? className : ''}`}
        src={url}
        alt={alternativeText || 'Strapi Image'}
        loading={data.lazy ? 'lazy' : 'eager'}
        style={{
          height: height ? `${height}px` : '',
          width: width ? `${width}px` : ''
        }}
      />
      {caption && <p>{caption}</p>}
    </>
  );
};

export default StrapiImageComponent;