// import fetch from "node-fetch";

/**
 * strapiFetchGraphQL Function
 *
 * Fetches data from a Strapi CMS using GraphQL. The function constructs a request using the provided
 * GraphQL query string. It requires the Strapi GraphQL endpoint URL and an authentication token to be set
 * in the environment variables. If successful, it returns the fetched data. Otherwise, it throws an error
 * with a detailed message.
 *
 * @throws {Error} Will throw an error if necessary environment variables or query aren't provided or if fetching fails.
 *
 * @returns {Promise<any>} Resolves with the fetched data.
 */

const formatGraphQLQuery = (query: string, errorLine: number | null = null) => {
  let indentation = 0;
  return query.split(/\n/g).map((line, index) => {
    line = line.trim();
    if (line.endsWith('{')) {
      line = '  '.repeat(indentation) + line;
      indentation++;
    } else if (line.startsWith('}')) {
      indentation = Math.max(0, indentation - 1);  // Ensure indentation doesn't go negative
      line = '  '.repeat(indentation) + line;
    } else {
      line = '  '.repeat(indentation) + line;
    }

    if (errorLine !== null && index === errorLine - 1) {
      return line + '\n' + '  '.repeat(indentation) + '^^^';  // Add marker to indicate error
    }

    return line;
  }).join('\n');
};

interface StrapiFetchOptions {
  headers?: Record<string, string>;
  method?: string;
  query: string;
}

const strapiFetchGraphQL = async ({
  headers,
  method = `POST`,
  query,
}: StrapiFetchOptions): Promise<any> => {
  const body = query ? JSON.stringify({ query }) : null;
  const bodyJSON = body ? JSON.parse(body) : null;

  if (
    query.includes(`query`) &&
    body &&
    bodyJSON?.query &&
    process.env.REACT_APP_STRAPI_URL &&
    process.env.REACT_APP_STRAPI_BEARER_TOKEN
  ) {
    try {
      const response = await fetch(`${process.env.REACT_APP_STRAPI_URL}/graphql`, {
        method,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_BEARER_TOKEN}`,
          "Content-Type": "application/json",
          ...headers,
        },
        body,
      });

      if (!response.ok) {
        const errorData = await response.json();  // Read the JSON body of the response
        const errorMessages = errorData.errors.map((error: any) => {
          return `Message: ${error.message}\nLocation: Line ${error.locations[0].line}, Column ${error.locations[0].column}\n`;
        }).join('');

        const errorLine = errorData.errors[0]?.locations[0]?.line || null;
        throw new Error(
          `HTTP error! Status: ${response.status}\n${errorMessages}Query:\n${formatGraphQLQuery(bodyJSON.query, errorLine)}`
        );
      }
      return await response.json();
    } catch (e) {
      throw new Error(
        `Error in strapiFetchGraphQL: A data-fetching error occurred.\n\n${e.message}`
      );
    }
  } else {
    throw new Error(
      `Error in strapiFetchGraphQL: process.env.REACT_APP_STRAPI_URL, process.env.REACT_APP_STRAPI_BEARER_TOKEN, and query must be present to fetch data.\n\nquery: ${query}\n\nbody: ${body}\n\nbodyJSON: ${JSON.stringify(
        bodyJSON,
        null,
        2,
      )}`
    );
  }
};

export default strapiFetchGraphQL;