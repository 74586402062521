import React from 'react';
import { Button } from '../Buttons';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import { Image } from 'shared/interfaces/Image';
import { isMobile } from 'react-device-detect';
import Text from 'components/Text';
import Wrapper from "components/Strapi/Wrapper";

import 'pure-react-carousel/dist/react-carousel.es.css';
import './_index.scss';

type ColumnType = 'threeColumnImage' | 'fourColumnImage' | 'fiveColumnImage' | 'horizontalImage';
type ImageType = 'Image' | 'Icon';

interface CTA {
  link: string;
  text: string;
}

interface Card {
  imageUrl?: Image;
  iconUrl?: Image;
  title: string;
  description: string;
  cta1?: CTA;
  cta2?: CTA;
}

interface ColumnCardsProps {
  cards: Card[];
  columnType: ColumnType;
  imageType: ImageType;
  bgColor?: {
    selectedColor: string
  };
  isMobileCarousel?: boolean;
}

interface ColumnCardItemProps {
  card: Card;
  imageType: ImageType;
  isMobileCarousel?: boolean;
}

const ColumnCards = ({ cards, columnType, imageType, bgColor, isMobileCarousel }: ColumnCardsProps): React.ReactElement => {

  return (
    <Wrapper bgColor={bgColor?.selectedColor} className='columnCards'>
      <div className="container container--large container--less-padding">
        {isMobileCarousel &&
          <div className={`columnCards__carousel-container`}>
            <CarouselProvider
              naturalSlideWidth={1}
              naturalSlideHeight={1}
              totalSlides={cards.length}
              isIntrinsicHeight={true}
              dragEnabled={isMobile}
              visibleSlides={1}
              className="columnCards__carousel-container__carousel"
            >
              <Slider>
                {cards.map((card, index) => (
                  <Slide key={index} index={index}>
                    <ColumnCardItem card={card} imageType={imageType} isMobileCarousel={isMobileCarousel} />
                  </Slide>
                ))}
              </Slider>
              <div className="columnCards__carousel-container__dot-container">
                <DotGroup />
              </div>
            </CarouselProvider>
          </div>
        }
        <div className={`columnGrid ${isMobileCarousel ? 'columnGrid__default' : ''} columnGrid--${columnType} ${imageType === 'Icon' ? 'columnGrid--icon' : ''} scrollbar--small`}>
          {cards.map((card, index) => (
            <ColumnCardItem key={index} card={card} imageType={imageType} />
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

const ColumnCardItem = ({ card, imageType, isMobileCarousel }: ColumnCardItemProps): React.ReactElement => {
  return (
    <div className={isMobileCarousel ? "column column--carousel" : "column"}>
      <div className="img-wrapper">
        {imageType === 'Image' && card.imageUrl && (
          <img src={card.imageUrl.image?.data.attributes.url} alt={card.title} />
        )}
        {imageType === 'Icon' && card.iconUrl && (
          <img src={card.iconUrl.image?.data.attributes.url} alt={card.title} />
        )}
      </div>
      <div className="content">
        <h3>{card.title}</h3>
        <Text text={card.description} />
        {card.cta1 && <Button text={card.cta1.text} link={card.cta1.link} customClassName='cta1 button--btn-blue button--large' />}
        {card.cta2 && <Button text={card.cta2.text} link={card.cta2.link} customClassName='cta2 button--green-underline' />}
      </div>
    </div>
  );
};

export default ColumnCards;
