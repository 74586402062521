import { mapColor } from "shared/helpers/colorMapping"

interface BGSpacingProps {
  bgColor?: string
  className?: string
  children: React.ReactNode
  noPadding?: boolean
}

const Wrapper = ({ bgColor, className, children, noPadding }: BGSpacingProps) => {
  return (
    <div className={!bgColor ? 'component__margin' : ''}>
      {(bgColor || className) ?
        <div className={`${bgColor ? `${!noPadding && 'background--padding'} background--${mapColor(bgColor)}` : ''} ${className}`}>
          {children}
        </div>
        : children
      }
    </div>
  )
}

export default Wrapper