import React from 'react';
import { Link } from 'shared/interfaces/Link';

interface LinkComponentProps {
  linkData: Link;
  children?: React.ReactNode;
  className?: string;
  programmaticOpen?: boolean;
}

const LinkComponent = ({ linkData, className, children, programmaticOpen = false }: LinkComponentProps): React.ReactElement => {
  const {
    href,
    target = '_self',
    rel = 'noopener noreferrer',
    ariaLabel
  } = linkData;

  const openLink = () => {
    if (target === 'self') {
      window.location.href = href;
    } else {
      window.open(href);
    }
  };

  if (programmaticOpen) {
    return (
      <div
        className={`link-component ${className}`}
        role="link"
        aria-label={ariaLabel}
        onClick={openLink}
      >
        {children}
      </div>
    );
  } else {
    return (
      <a
        className={`link-component ${className}`}
        href={href}
        target={target}
        rel={rel}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    );
  }
};

export default LinkComponent;