import React, { useState } from "react";
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { Button } from "components/Buttons";

import StrapiImage from 'components/Strapi/Image';
import StrapiButton from 'components/Strapi/Button';
import Wrapper from "components/Strapi/Wrapper";

import { Button as ButtonProps } from 'shared/interfaces/Button';
import { Image as ImageProps } from 'shared/interfaces/Image';

import "./stylesheets/news-card-carousel.scss";
import 'pure-react-carousel/dist/react-carousel.es.css';

type NewsCardCarouselProps = {
  tabs: {
    image: ImageProps;
    storyType: string;
    storyHeader: string;
    content: string;
    cta?: ButtonProps;
    url?: string;
  }[]
  bgColor?: {
    selectedColor: string
  };
};

interface CustomDotGroupProps {
  setCurrentIndex: (index: number) => void;
  totalSlides: number;
  currentIndex: number;
}

const CustomDotGroup = ({ setCurrentIndex, totalSlides, currentIndex }: CustomDotGroupProps): React.ReactElement => {
  return (
    <div className="news-card__custom-dots">
      {Array.from({ length: totalSlides }).map((_, i) => {
        const isActive = i === currentIndex;
        return (
          <button
            key={i}
            onClick={() => setCurrentIndex(i)}
            className={`news-card__custom-dot ${isActive ? 'news-card__custom-dot--selected' : ''}`}
          >
          </button>
        );
      })}
    </div>
  );
};

export const NewsCardCarousel = (props: NewsCardCarouselProps): React.ReactElement => {
  const { tabs, bgColor } = props;

  const [selectedTab, setSelectedTab] = useState(0);

  const nextSlide = () => {
    if (selectedTab < tabs.length - 1) {
      setSelectedTab(selectedTab + 1);
    } else {
      setSelectedTab(0); // Loop back to the first slide
    }
  };

  const previousSlide = () => {
    if (selectedTab > 0) {
      setSelectedTab(selectedTab - 1);
    } else {
      setSelectedTab(tabs.length - 1) // Loop to the last slide
    }
  }

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className="container container--large">
        <div className="news-card">
          <div className="news-card__mobile">
            <CarouselProvider
              naturalSlideWidth={3}
              naturalSlideHeight={3}
              totalSlides={tabs.length}
              currentSlide={selectedTab}
              isIntrinsicHeight={true}
              dragEnabled={true}
              visibleSlides={1}
              infinite={true}
              className="news-card__mobile__carousel"
            >
              <Slider>
                {tabs.map(({ image, storyHeader, storyType, content, cta, url }, index) => {
                  return (
                    <Slide index={index} key={index}>
                      <div className="news-card__mobile__content">
                        <div className="news-card__mobile__top-section">
                          <div className="news-card__mobile__imgWrapper">
                            <StrapiImage data={image} />
                          </div>
                          <div className="news-card__mobile__top-section__headers">
                            {cta && (
                              <StrapiButton data={cta} customClassName="button--news-card-carousel-story" />
                            )}
                            <h5>{storyType}</h5>
                          </div>
                        </div>
                        <div className="news-card__mobile__bottom-section">
                          <h3>{storyHeader}</h3>
                          <p>
                            {content}... <Button text="Keep Reading" link={url} customClassName="button--news-card-brand" />
                          </p>
                        </div>
                      </div>
                    </Slide>
                  )
                })}
              </Slider>
            </CarouselProvider>
          </div>
          <div className="news-card__desktop">
            <div className="news-card__button-left-arrow">
              <Button
                onClick={() => previousSlide()}
                customClassName="button--news-card-arrow"
                icon={"arrow-left"}
              />
            </div>
            <div className="news-card__desktop__box-container">
              <CarouselProvider
                naturalSlideWidth={3}
                naturalSlideHeight={3}
                totalSlides={tabs.length}
                currentSlide={selectedTab}
                isIntrinsicHeight={true}
                dragEnabled={false}
                visibleSlides={1}
                className="news-card__desktop__carousel"
              >
                <Slider>
                  {tabs.map(({ image, storyHeader, storyType, content, cta, url }, index) => {
                    return (
                      <Slide index={index} key={index}>
                        <div className="news-card__desktop__container">
                          <div className="news-card__desktop__imgWrapper">
                            <StrapiImage data={image} />
                          </div>
                          <div className="news-card__desktop__content">
                            <div className="news-card__desktop__headers">
                              {cta && (
                                <StrapiButton data={cta} customClassName="button--news-card-carousel-story" />
                              )}
                              <h3>{storyType}</h3>
                            </div>
                            <div className="news-card__desktop__bottom-section">
                              <h5>{storyHeader}</h5>
                              <p>
                                {content}... <Button text="Keep Reading" link={url} customClassName="button--news-card-brand" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    )
                  })}
                </Slider>
              </CarouselProvider>
            </div>
            <div className="news-card__button-right-arrow">
              <Button
                onClick={() => nextSlide()}
                customClassName="button--news-card-arrow"
                icon={"arrow-right"}
              />
            </div>
          </div>
          <CustomDotGroup
            setCurrentIndex={setSelectedTab}
            totalSlides={tabs.length}
            currentIndex={selectedTab}
          />
        </div>
      </div>
    </Wrapper>
  );
}